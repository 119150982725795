import { BrowserModule } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import { NgModule, Inject } from '@angular/core';

import { AppComponent } from './app.component';

import { SharedModule, BrandService } from '@whitehatgaming/shared';

import { AppRoutingModule } from './app-routing/app-routing.module';

import { environment } from '../environments/environment';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, Observable, skip } from 'rxjs';
import { generalIframeQueryParams } from './models/iframeParams.enum';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule, // first to declare wins
    SharedModule.forRoot(null, environment),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(DOCUMENT) private document: Document, private brand: BrandService, private route: ActivatedRoute) {
    const queryParamMap$: Observable<ParamMap> = this.route.queryParamMap.pipe(skip(1));

    combineLatest([this.brand.platformSetupSubject, queryParamMap$]).subscribe(([_, queryParams]) => {
      const skipThemeSetup: boolean = queryParams?.get(generalIframeQueryParams.skipThemeSetup) === 'true';
      if (!skipThemeSetup) {
        const head = this.document.getElementsByTagName('head')[0];
        const style = this.document.createElement('link');
        style.rel = 'stylesheet';

        style.href = this.brand.url + '/css/whg-iframe/theme.css';

        head.appendChild(style);
      }
    });
  }
}
